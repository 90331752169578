<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">职前必学课程</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div class="df" style="padding-left: 10px">
              <el-button type="primary" class="bgc-bv" round @click="setAddAndEdit()">新增</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="名称" align="left" show-overflow-tooltip prop="trainingName" min-width="200" />
              <el-table-column label="类型" align="left" show-overflow-tooltip prop="trainingType" width="200">
                <template slot-scope="scope">
                  {{ $setDictionary("INTERN_TRAINING_TYPE", scope.row.trainingType) }}
                </template>
              </el-table-column>
              <el-table-column label="状态" align="center" show-overflow-tooltip prop="publishState" min-width="200">
                <template slot-scope="scope">
                  {{ scope.row.publishState ? '已发布' : '未发布' }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="160px" fixed="right">
                <div slot-scope="scope">
                  <el-button type="text" size="mini" style="padding: 0 5px"
                    @click="setAddAndEdit(scope.row)">编辑</el-button>
                  <el-button type="text" size="mini" style="padding: 0 5px" @click="releaseData(scope.row)">{{
                    scope.row.publishState ? '回收' : '发布' }}</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog :title="addAndEditData.trainingId ? '编辑' : '新增'" :visible.sync="addAndEditData.dialogVisible"
      width="500px" :modal="false">
      <el-form ref="addAndEditData" class="addAndEditData" :model="addAndEditData" :rules="addAndEditDataRules"
        label-width="120px" size="small" :before-close="cancelData">
        <el-form-item label="名称" prop="trainingName">
          <el-input v-model="addAndEditData.trainingName" placeholder="请输入名称" maxlength="30" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="trainingType">
          <el-radio-group v-model="addAndEditData.trainingType">
            <el-radio v-for="(item, index) in trainingTypeData" :key="index" :label="item.value">{{ item.label
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选择课程" prop="courseId" class="dSelect">
          <el-select size="small" v-model="addAndEditData.courseId" remote :remote-method="getCompanyList" filterable
            clearable placeholder="请至少输入两个字搜索">
            <el-option v-for="item in companyList" :key="item.courseId" :label="item.courseName"
              :value="item.courseId"></el-option>
          </el-select>
          <el-button type="text" @click="openPreviewVideoData">预览</el-button>
        </el-form-item>
        <el-form-item label="选择试卷" prop="paperId" class="dSelect">
          <el-select size="small" v-model="addAndEditData.paperId" remote :remote-method="getCompanyList1" filterable
            clearable placeholder="请至少输入两个字搜索">
            <el-option v-for="item in companyList1" :key="item.paperId" :label="item.paperName"
              :value="item.paperId"></el-option>
          </el-select>
          <el-button type="text" @click="openPreviewChaptersData()">预览</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" class="bgc-bv" size="small" @click="cancelData">取消</el-button>
        <el-button type="primary" class="bgc-bv" size="small" @click="saveData">保存</el-button>
      </div>
    </el-dialog>
    <PaperShow ref="PaperShow" :modal="false" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import PaperShow from "@/views/resourse/popup/PaperShow";
export default {
  name: "compulsoryCourses",
  components: {
    Empty,
    PageNum,
    PaperShow,
  },
  mixins: [List],
  data() {
    return {
      // 新增&编辑课程 - 弹框
      addAndEditData: {
        dialogVisible: false, // 弹框显示
        trainingName: "", // 名称
        trainingType: "10", // 类型
        courseId: "", // 课程id
        paperId: "", // 试卷id
      },
      // 新增&编辑课程 - 培新类型 - 单选数据
      trainingTypeData: [],
      // 新增&编辑课程 - 课程 - 下拉数据
      companyList: [],
      // 新增&编辑课程 - 试卷 - 下拉数据
      companyList1: [],
      // 新增&编辑课程 - 数据校验
      addAndEditDataRules: {
        trainingName: [
          { required: true, message: "请输名称", trigger: "blur" },
        ],
        trainingType: [
          { required: true, message: "请选择类型", trigger: "change" },
        ],
        courseId: [
          { required: true, message: "请选择课程", trigger: "change" },
        ],
        paperId: [
          { required: true, message: "请选择试卷", trigger: "change" },
        ],
      },
    };
  },
  watch: {},
  created() {
    this.getInvoice_type();
  },
  computed: {},
  mounted() { },
  methods: {
    // 获取 - 获取字典
    getInvoice_type() {
      // 类型
      let arr1 = this.$setDictionary("INTERN_TRAINING_TYPE", "list");
      for (const key in arr1) {
        this.trainingTypeData.push({
          label: arr1[key],
          value: key
        });
      }
    },
    // 获取列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      this.doFetch({
        url: "/biz/intern/train/pageList",
        params,
        pageNum,
      });
    },
    // 新增&编辑课程
    setAddAndEdit(row) {
      if (row?.trainingId) {// 编辑
        // this.addAndEditData = { ...this.addAndEditData, ...row }
        this.$post("/biz/intern/train/info", {
          trainingId: row.trainingId,
        }).then((res) => {
          this.addAndEditData = { ...this.addAndEditData, ...res.data };
          this.getCompanyList(res.data.courseName);
          this.getCompanyList1(res.data.paperName);
        }).catch(() => {
          return;
        });

      }
      this.addAndEditData.dialogVisible = true;
    },
    // 获取 - 课程下拉数据
    getCompanyList(query) {
      if (query && query.trim().length >= 2) {
        this.$post("/biz/intern/train/listPage", {
          courseName: query.trim(),
          pageNum: 1,
          pageSize: 10,
        }).then((res) => {
          console.log(res.data.list)
          this.companyList = res?.data?.list ?? [];
        }).catch(() => {
          return;
        });
      } else {
        this.companyList = [];
      }
    },
    // 获取 - 课程下拉数据
    getCompanyList1(query) {
      if (query && query.trim().length >= 2) {
        this.$post("/biz/intern/train/queryList", {
          paperName: query.trim(),
          pageNum: 1,
          pageSize: 10,
        }).then((res) => {
          this.companyList1 = res?.data?.list ?? [];
        }).catch(() => {
          return;
        });
      } else {
        this.companyList1 = [];
      }
    },
    // 预览课程
    openPreviewVideoData() {
      if (this.addAndEditData.courseId) {
        this.$router.push({
          path: "/web/detailResource",
          query: {
            courseId: this.addAndEditData.courseId,
            stu: "zhiqianbixue",
          },
        });
      } else {
        this.$message({
          type: "warning",
          message: "请先选择课程!",
        });
      }
    },
    // 预览章节 
    openPreviewChaptersData() {
      if (this.addAndEditData.paperId) {
        this.$refs.PaperShow.showPopUp(this.addAndEditData.paperId); // 打开弹窗预览试题
      } else {
        this.$message({
          type: "warning",
          message: "请先选择试卷!",
        });
      }
    },
    // 新增&编辑 - 取消 cancel
    cancelData() {
      this.addAndEditData = this.$options.data().addAndEditData;
    },
    // 新增&编辑 - 确认
    saveData() {
      this.$refs['addAndEditData'].validate((valid) => {
        if (valid) {
          this.$post("/biz/intern/train/insertOrModify", {
            trainingId: this.addAndEditData.trainingId || '',
            trainingName: this.addAndEditData.trainingName,
            trainingType: this.addAndEditData.trainingType,
            courseId: this.addAndEditData.courseId,
            paperId: this.addAndEditData.paperId,
          }).then((res) => {
            this.cancelData();
            this.getData();
            this.$message.success("操作成功");
          }).catch(() => {
            return;
          });
        }
      });
    },
    // 发布&收回
    releaseData(row) {
      this.$post("/biz/intern/train/modifyState", {
        trainingId: row.trainingId
      }).then((res) => {
        this.getData(this.pageNum);
        this.$message.success("操作成功");
      }).catch(() => {
        return;
      });
    }
  },
};
</script>
<style lang="less" scope>
.addAndEditData {
  .dSelect {
    .el-form-item__content {
      display: flex;
      .el-select {
        width: 100%;
        margin-right: 10px;
      }
    }
  }
}
</style>